@import "src/sass/main.scss";

.blur {
  color: $blur-text-color;
}

.container {
  margin: 1em 0;
  padding: 1em;
  height: auto;
  background-color: $light-bg-color;
}

.terms {
  margin: 1em 0;
}

.terms-download {
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.spacing-checkbox {
  margin-right: 10px;
  margin-top: 15px;
}

.note {
  font-size: 10px;
}
