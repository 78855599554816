@import "../../sass/main.scss";

.form {
  &__control {
    display: block;
    margin: 1rem 0;

    &--error {
      color: $color-warning;
      padding: 0.5rem 0;
    }

    &--input {
      width: 10rem;
    }
  }
}

.fields-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  .field-item {
    width: 20rem;
    padding: 0 2rem 0 0;

    .message {
      font-size: x-small;
    }
  }
}

.esp-note {
  font-size: small;
}

.welcome-checkbox {
  margin-right: 5rem;
  margin-top: 2rem;
}
