@import "../../sass/main.scss";

.sweepstakes-deliverables {
  margin: 0 auto;

  .form__control {
    &__video-upload {
      background-color: #edeeef;
      width: 80%;
      padding: 2rem;
      font-size: 1rem;
    }
  }

  .video-preview {
    width: 80%;
  }
}
