@import "../../sass/main.scss";

$logo-top-margin: 1.125rem;
$logo-height: 3.125rem;
$image-width: 40%;
$content-width: 60%;

.onboarding {
  position: relative;
  height: 100vh;

  @include media-query($medium-up) {
    display: flex;
  }

  &-logout-btn {
    display: none;

    @include media-query($medium-up) {
      position: absolute;
      top: 1.625rem;
      right: 3.75rem;
      display: block;
      font-family: $font-primary-new;
      color: $color-primary-light;
      font-size: 0.875rem;
      line-height: 1.125rem;
      font-weight: 300;
      letter-spacing: 0.15rem;
      cursor: pointer;
    }
  }

  &-right-panel {
    display: none;

    @include media-query($medium-up) {
      width: $image-width;
      background-color: #000;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &.billing-details-container {
      justify-content: start;
    }
  }

  &-header {
    width: 100%;
    padding: 2.75rem 0 0 1.125rem;

    @include media-query($medium-up) {
      width: $content-width;
      position: fixed;
      top: 0;
      background-color: #fff;
      z-index: 10;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0 4rem 1.125rem 2.5rem;
    }

    &__logo {
      height: $logo-height;

      @include media-query($medium-up) {
        margin-top: $logo-top-margin;
      }
    }
  }

  &-content {
    overflow: auto;

    @include media-query($medium-up) {
      width: $content-width;
      padding-top: $logo-height + $logo-top-margin;
    }
  }

  &-form {
    &-container {
      padding: 2rem 2.5rem 5rem 2.5rem;

      @include media-query($medium-up) {
        max-width: 61.5rem;
        margin: 0 auto;
        padding: 3.375rem 5.25rem;
      }
    }

    &-block {
      margin-bottom: 3.75rem;
    }

    &-row {
      @include media-query($medium-up) {
        display: flex;
        margin-bottom: 0.5rem;

        & > :not(:last-child) {
          margin-right: 3.25rem;
        }
      }
    }
  }

  &-title {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 800;
    font-family: $font-primary-new;
    letter-spacing: 0.1em;
  }

  &-paragraph {
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 400;
    font-family: $font-primary-new;
    letter-spacing: 0.1rem;
    margin: 0;
  }

  &-info {
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-family: $font-primary-new;
    letter-spacing: 0.1rem;
    font-weight: 400;
    margin-bottom: 0.625rem;
  }

  &-button {
    display: block;
    font-family: $font-primary-new;
    color: $color-primary-light;
    background-color: #000;
    font-size: 1.125rem;
    line-height: 1.5rem;
    text-align: center;
    letter-spacing: 0.1rem;
    padding: 0.75rem 1.5rem;
    font-weight: 500;
    cursor: pointer;

    @include media-query($medium-up) {
      padding: 0.75rem 4rem;
    }

    &:hover {
      background-color: $color-secondary;
    }
  }

  &-back-btn {
    display: block;
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-family: $font-primary-new;
    letter-spacing: 0.1rem;
    font-weight: 400;

    &::before {
      content: "";
      display: inline-block;
      margin-right: 0.5rem;
      width: 0.75rem;
      height: 0.5rem;
      background-image: url(../../assets/images/left-arrow.svg);
    }
  }
}
