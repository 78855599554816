@import "../../../sass/main";

.custom-upload-preview {
  position: relative;
  display: flex;

  &__border {
    display: flex;
    position: relative;
    padding: 1.5rem;
    background-color: $form-field-bg;
    align-items: center;
  }
  
  &__close-icon {
    width: 1.8rem;
    height: 1.8rem;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5rem;
    cursor: pointer;
  }

  &__preview {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
}
