@import "../../../../sass/main.scss";

.paragraph {
  &--primary {
    margin-top: 1.06rem;
    margin-bottom: 1.43rem;

    @include media-query($medium-up) {
      font-size: 0.875rem;
      letter-spacing: 1px;
      line-height: 1.4;
      margin: 0.6rem 0;
    }
  }

  &--secondary {
    margin: 1rem 0;
    font-size: 0.70rem;
  }
}
