@import "../../../sass/main.scss";

.advertising {
  @include media-query($medium-up) {
    display: flex;
    min-height: 100vh;
    background-color: #fdfdfd;
  }

  .content2 {
    min-height: 40rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1.75rem 2.62rem;

    @include media-query($medium-up) {
      margin: 4.6rem 8.5rem 3.1rem;
    }
  }

  .section {
    max-width: 50rem;
  }
}
