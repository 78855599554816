@import "../../sass/main.scss";

.image-uploader {
  display: grid;
  grid-template-rows: 1fr;
  gap: 1rem;

  .upload-section {
    &__btn {
      display: inline-block;
      border-radius: 4px;
      font-weight: 600;
      letter-spacing: 4px;

      &:hover {
        background-color: black;
        color: white;
      }
    }

    &__error {
      padding: 1rem 0;
      color: $color-warning;
    }
  }

  .images-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    .wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
  }

  .image-item {
    position: relative;
    height: 12.75rem;
    padding: 1rem;
    background-color: $color-primary-dark;
    overflow: hidden;

    &__btn {
      position: absolute;
      color: $color-secondary;
      border: none;
      background-color: transparent;
      top: 4px;
      right: 4px;
      cursor: pointer;
    }

    &__name {
      margin-top: 0.5rem;
      margin-left: 0.5rem;
    }

    &__thumbnail {
      display: block;
      width: 100%;
      height: 8rem;
      object-fit: cover;
    }

    &__title,
    &__description {
      width: 100%;
      padding: 1rem;
      border: none;
      background-color: $color-primary;
      font-family: Arial, Helvetica, sans-serif;
    }

    &__title {
      padding-right: 5rem;

      &--limit {
        position: absolute;
        right: 1rem;
        bottom: 10.5rem;
        font-size: 0.8rem;
      }
    }

    &__description {
      flex: 1;
      align-self: center;

      &--limit {
        position: absolute;
        right: 1rem;
        bottom: 0.5rem;
        font-size: 0.8rem;
      }

      &--required {
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        font-size: 0.9rem;
      }
    }
  }
}

.disable-pointer-event {
  pointer-events: none;
}
