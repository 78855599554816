@import "../../sass/main.scss";

.imageUploader {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 0.3fr 1.7fr;
  gap: 1em 1em;
  grid-template-areas:
    "uploadBtn"
    "imagesData";
}

.uploadSection {
  grid-area: "uploadBtn";

  &__btn {
    margin: auto;
    padding: 1.5em 3em;
    border: 1px solid $color-secondary;
    border-radius: 0.2em;
    background-color: transparent;
    font-weight: 600;
    letter-spacing: 0.2em;
    display: inline-block;

    &:hover {
      background-color: black;
      color: white;
    }
  }

  &__error {
    padding: 1rem 0;
    color: $color-warning;
  }
}

.imagesData {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 20px;
}

.imageData {
  position: relative;
  padding: 1em;
  background-color: $color-primary-dark;
  overflow: hidden;

  &__btn {
    position: absolute;
    color: $color-secondary;
    border: none;
    background-color: transparent;
    top: 4px;
    right: 4px;
  }

  &__name {
    margin-top: 0.5rem;
    margin-left: 0.5rem;
  }

  &__thumbnail {
    display: block;
    width: 100%;
    height: 5rem;
    object-fit: cover;
  }
}

.disable-pointer-event {
  pointer-events: none;
}
