@import "./main.scss";

::placeholder {
  color: $form-field-text-color;
}

.form {
  &-question {
    font-weight: 600;
    letter-spacing: 0.2em;
    text-transform: uppercase;

    &__text {
      color: $color-secondary;
    }

    &__error {
      display: block;
      margin-top: 1rem;
      color: $color-warning;
      font-size: 0.8em;
    }
  }

  &-field {
    display: flex;
    flex-direction: column;
    padding-top: 1em;

    &__label {
      margin-bottom: 0.5rem;
    }

    &__input {
      margin-right: 0.5rem;
    }
  }

  &-button {
    margin-right: auto;
    padding: 1.5em 3em;
    border: 1px solid $color-secondary;
    border-radius: 0.2em;
    background-color: transparent;
    font-weight: 600;
    letter-spacing: 0.2em;

    &:hover {
      background-color: black;
      color: white;
    }
  }

  &__input {
    margin: 0.5rem 0;
    margin-right: 3rem;
    padding: 0.7em;
    border: none;
    color: $color-secondary;
    background-color: $form-field-bg;
    font-size: 1em;
    font-family: Arial, Helvetica, sans-serif;

    &--select {
      width: 9rem;
    }
  }
}
