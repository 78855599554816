@import "../../../sass/main";

.custom-field {
  padding: 1rem 0;

  &--secondary {
    padding: 0;
  }

  &__input {
    background-color: $color-secondary-dark;
    padding: 0.625rem 1.1rem;
    border: unset;
    font-family: inherit;
    letter-spacing: 1px;
    width: 100%;

    &--error {
      border-left: 3px solid $color-warning;
    }
  }

  &__validation-message {
    display: block;
    margin: 0.5rem 0;
    color: $color-warning;
    font-size: 0.75rem;
    line-height: 0.75rem;

    &--info {
      color: inherit;
    }
  }
}
