@import "../../sass/formsStyles.scss";
@import "../../sass/abstracts/_variables.scss";

.sms-form {
  a {
    color: $color-link-blue;
  }

  .message {
    font-size: x-small;
  }

  .form__unsubscribe {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 80%;

    .form__input {
      width: 10rem;
    }
  }
}
