@import "../../../../../sass/main.scss";

.steps__step-item {
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;

  &--selected {
    font-weight: 600;
  }

  &__title {
    padding-top: 0.15em; // Visually center uppercase
  }

  &__link {
    display: inherit;
    justify-content: inherit;
    align-items: inherit;
    text-decoration: inherit;
    color: inherit;
  }
}
