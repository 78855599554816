@import "../../../sass/main.scss";

.company-information {
  .onboarding-title {
    margin-bottom: 2rem;
  }

  .location-row > :not(:last-child) {
    margin-right: 3.75rem;
  }

  .input-city {
    @include media-query($medium-up) {
      width: 15.125rem;
    }
  }

  .input-state,
  .input-extension {
    width: 10.5rem;
  }

  .input-zip {
    width: 8.625rem;
  }

  .input-website {
    @include media-query($medium-up) {
      width: 23.25rem;
    }
  }

  .onboarding-button {
    margin-top: 3.5rem;
    width: 100%;

    @include media-query($medium-up) {
      margin-top: 1.625rem;
    }
  }
}
