.form {
  margin: 2rem 0 1rem 0;

  &__group {
    margin: 1rem 0;
  }

  .radio-item:not(:last-child) {
    margin-bottom: 1rem;
  }
}
