// margins

.u-mb-extra-large {
  @include media-query($medium-up) {
    margin-bottom: 4rem;
  }
}
.u-mb-large {
  margin-bottom: 2rem;
}
.u-mb-medium {
  margin-bottom: 1rem;
}
.u-mb-small {
  margin-bottom: 0.5rem;
}
