.link {
  color: $color-link-light-blue;
  text-decoration: underline;

  &--black {
    color: black;
  }

  &--blue {
    color: blue;
  }
}
