@import "../../../sass/main";

.custom-field_label {
  display: block;
  @extend .heading-tertiary;
  @extend .bold;

  margin: 1em 0 0.5em;

  &--secondary {
    font-weight: normal;
    text-transform: none;
  }

  &--dark {
    color: $color-primary-light;
    background-color: $color-dark;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    letter-spacing: inherit;
    margin: 0.5rem 0 1rem 0;
  }

  &--lowercase {
    text-transform: none;
  }
}
