@import "../../sass/abstracts/variables.scss";
.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: $color-secondary-transparency;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.modal-content {
  width: 55%;
  padding: 3em;
  background-color: white;
}
