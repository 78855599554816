@import "../../../../../sass/main.scss";

.menu-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 3rem;
  height: 3rem;
  background-color: transparent;
  color: white;

  @include media-query($medium-up) {
    display: none;
  }
}
