@import "../../sass/main.scss";

.sweep-link {
  margin-bottom: 1rem;

  &__details {
    margin-top: 1rem;
    margin-left: 4rem;
    list-style: disc;
  }

  &__brands {
    margin-top: 4px;
    margin-left: 1rem;
    list-style: disc;
  }

  &__brand-name {
    font-weight: 500;
    text-decoration: underline;
  }

  &__brand-detail__item {
    font-weight: 600;
    color: $color-purple-primary;
  }

  &__message {
    margin-left: -1rem;
    font-style: italic;
  }
}

.collapse-button {
  margin-left: 0.5rem;
  cursor: pointer;
  transform: rotate(0deg);

  &--collapse {
    transform: rotate(90deg);
  }
}
