@import "../../../../../sass/main.scss";

.label {
  margin-bottom: 0.68rem;
  font-size: 0.75rem;

  @include media-query($medium-up) {
    font-size: 0.875rem;
  }
}
