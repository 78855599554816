.content {
  width: 100%;
  // content - header - footer
  min-height: calc(100vh - 9rem - 18rem);
  margin: 3rem 0 0 3rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
}
