@import "../../../../../../sass/main.scss";

.flex-wrap-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 6.37rem;

  @include media-query($medium-up) {
    gap: 2.81rem;
  }

  .important-message {
    margin-top: -2rem;

    @include media-query($medium-up) {
      margin-top: -3rem;
    }
  }
}
