.btn {
  border: 0;
  padding: 1rem 4rem;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 3px;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: $color-secondary;
  }

  &--primary {
    background-color: rgba($color-secondary, $alpha: 0.4);
  }

  &--secondary {
    display: inline-block;
    margin: 0.5rem 0 1rem 0;
    padding: 0.8rem 3rem;
    background-color: white;
    color: black;
    border: 2px solid black;

    &:hover {
      background-color: $color-primary-light;
    }
  }

  &--submit {
    margin: 1rem 0;
    padding: 1rem 4rem;
    background-color: black;
    font-size: small;
  }

  &--small {
    padding: 0.2rem 0.5rem;
  }
}
