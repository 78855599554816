@import "../../../../../sass/main.scss";

.submit-button {
  border: unset;
  width: 100%;
  padding: 1rem 4rem;
  margin: 2.5rem 0 1rem;
  font-weight: normal;
  letter-spacing: 3px;
  color: white;
  cursor: pointer;
  background-color: black;

  @include media-query($medium-up) {
    max-width: 18.75rem;
  }

  &:hover {
    background-color: $color-secondary;
  }

  &:disabled {
    cursor: wait;

    &:hover {
      background-color: black;
    }
  }

  &__spinner {
    margin-right: 0.5rem;
  }
}
