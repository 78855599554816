.asset-collection-form {
  &__logo-examples {
    width: 478px;
    max-width: 100%;
  }
}

.product-images__description-list {
  list-style-type: disc;
  padding-left: 1.2rem;

  li {
    padding-left: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
