@import "../../sass/main.scss";

.textarea__wrapper {
  position: relative;
  width: 100%;
}

.textarea {
  display: block;
  resize: none;
  width: 100%;
  height: 6rem;
  padding: 0.5rem;
  border: none;
  background-color: $color-primary;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;

  &__characters {
    position: absolute;
    bottom: 0.5rem;
    right: 0.2rem;
    width: 4rem;
    font-size: 0.8rem;
    text-align: right;

    &--warning {
      color: $color-warning;
      font-weight: bold;
    }
  }

  &--error {
    border-left: 3px solid $color-warning;
  }
}
