// Typography
%heading {
  font-family: $font-heading;
}

body {
  font-family: $font-primary;
  letter-spacing: 1px;
}

.heading-primary {
  @extend %heading;

  margin-bottom: 1rem;
  font-size: 2.6rem;
  font-weight: normal;
  line-height: 1.2;
}

.heading-tertiary {
  letter-spacing: 3px;
  font-size: 1rem;
  text-transform: uppercase;
}

.paragraph-primary {
  margin: 4px auto;
  font-size: 1rem;
}

.paragraph-secondary {
  letter-spacing: 1px;
  font-size: 0.9rem;
}

.paragraph-tertiary {
  margin: 0.5rem 0;
  font-size: 0.7rem;
}

.black-link {
  text-decoration: underline;
  cursor: pointer;
}

.quote {
  font-style: italic;
  font-weight: 500;
}

.bold {
  font-weight: bold;
}

.heavy {
  font-weight: 500;
}

.success {
  color: $color-green-primary;
}

.warning {
  color: $color-warning;
}

.capitalize {
  text-transform: capitalize;
}

.capitalize-first-letter::first-letter {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.center {
  text-align: center;
}

$font-primary-new: "Avenir", Arial, Helvetica, sans-serif;
