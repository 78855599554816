@import "../../../../../../../sass/main.scss";

.product-images {
  padding-bottom: 1rem;

  &__list {
    display: grid;
    grid-template-columns: 13rem auto;
    gap: 1rem;
    padding-bottom: 1rem;
  }

  &__preview {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  &__upload-area {
    background: $color-secondary-dark;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5rem 3rem;
    box-sizing: border-box;
    position: relative;

    &--active {
      &:before {
        display: block;
        content: " ";
        position: absolute;
        inset: 0;
        border: 3px dashed $color-secondary-transparency;
        pointer-events: none;
      }
    }

    &__icon {
      max-width: 5rem;
      margin-bottom: 2rem;
      pointer-events: none;
    }
  }

  &__button--active {
    color: $blur-text-color;
    pointer-events: none;
  }

  &__upload-more-button {
    padding: 3rem 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
