@import "../../sass/main.scss";

.payment-reminder {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-error-background;
  color: $color-error;
  width: 100%;
  height: 100%;
  padding: 1rem;
  z-index: 1;

  @include media-query($medium-up) {
    padding: 1.5rem 1rem;
  }

  &__content {
    display: flex;
    margin: 0 auto;
    flex-direction: column;

    @include media-query($medium-up) {
      flex-direction: row;
    }
  }

  &__info {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    flex-grow: 0;
  }

  &__message {
    display: inline-block;
    flex-grow: 0;
    margin: 0 1rem;
    line-height: 1.2;

    @include media-query($medium-up) {
      margin: 0 2rem;
    }
  }

  &__button {
    background-color: $color-error;
    color: $color-error-background;
    border-radius: 8px;
    padding: 0.75rem 2rem;
    font-size: inherit;
    margin: 1rem auto 0 2.8rem;
    cursor: pointer;

    @include media-query($medium-up) {
      margin: 0;
    }
  }
}
