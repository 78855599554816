@import "../../sass/main.scss";

.checkbox {
  min-width: 2.5rem;
  min-height: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
  margin: 1rem;
  border: 1px solid black;
  border-radius: 50%;
  background-color: rgba($color-primary-light, 1);

  &--checked {
    background-color: rgba($color-green-primary, 1);
  }

  &__icon {
    color: $color-primary-light;
    font-size: 1.9rem;
    margin: 0.25rem;
  }
}
