.complete-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.heading-subtitle {
  padding: 1em 0;
  font-size: 1em;
  letter-spacing: 5px;
  text-transform: uppercase;
  font-weight: bold;
  &__no-bold {
    margin: 0;
    text-transform: uppercase;
  }
}
