@import "../../sass/main.scss";

.cpl-analytics {
  font-size: 0.9rem;

  &__title {
    margin-bottom: 1rem;
  }

  &__subtitle {
    font-size: 0.9rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
}

.cpl-bidding {
  &__title {
    @extend %heading;
    font-size: 1.8rem;
    font-weight: lighter;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  &__grid-item {
    padding: 1.5rem;
    background-color: $color-primary;
  }
}
