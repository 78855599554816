@import "../../../../sass/main.scss";

.navbar-v2 {
  background-color: black;

  @include media-query($medium-up) {
    max-width: 17rem;
    padding: 1rem 0;
    color: white;
  }
}

.brand-logo {
  height: 4rem;
  padding: 0.75rem;

  > img {
    display: block;
    height: 100%;
    margin: 0 auto;
  }

  @include media-query($medium-up) {
    margin-bottom: 3rem;

    > img {
      width: auto;
      margin-left: 1.5rem;
    }
  }
}
