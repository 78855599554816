@import "../../sass/main";

.promotion {
  width: 100%;

  &__thanks-msg {
    margin: 1rem 0 2.5rem 0;
  }
}

.form-promotion {
  &__title {
    font-family: Arapey, Arial, Helvetica, sans-serif;
    font-size: 1.6rem;
    font-weight: normal;
    padding: 0.5rem 0;
  }

  &__subtitle {
    font-size: 0.7rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 3px;
    padding: 0.5rem 0;
  }

  &__reminder {
    margin: 2rem 0;

    &-title {
      letter-spacing: 2px;
      font-size: 0.8rem;
      font-weight: bold;
      text-transform: uppercase;
    }

    &-text {
      margin-top: 0;
      font-size: 0.8rem;

      a {
        text-decoration: underline;
      }
    }
  }

  &__copy-box {
    display: flex;
    max-width: 43rem;
    border: 1px solid black;
  }
}
