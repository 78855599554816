@import "../../../../../sass/main.scss";

.cpl-esp-container {
  font-family: $font-primary-new;

  label,
  option {
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 400;
    letter-spacing: 1px;
  }

  .btn--submit {
    width: 100%;
    font-family: $font-primary-new;
    font-size: 1.125rem;
    line-height: 1.5rem;
    text-align: center;
    letter-spacing: 0.1rem;
    padding: 0.75rem 1.5rem;
    font-weight: 500;
    cursor: pointer;
    text-transform: capitalize;
    margin-top: 5rem;

    @include media-query($medium-up) {
      width: 19.25rem;
      padding: 0.75rem 4rem;
    }
  }

  .section__paragraph--primary:last-of-type {
    margin-top: 4rem;

    a {
      color: $color-link-light-blue;
    }
  }
}
