@import "../../../../sass/main.scss";

.heading {
  &--primary {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.4;
    letter-spacing: 3px;

    @include media-query($medium-up) {
      font-size: 1.5rem;
    }
  }
}
