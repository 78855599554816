.navigation-arrows {
  display: grid;
  grid-template-columns: 1rem 1rem 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  gap: 0.5rem;
  margin: 2rem auto;

  &--right:active,
  &--left:active {
    width: 1rem;
    padding: 2px;
    outline: auto;
  }
}
