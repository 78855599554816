@import "../../sass/main.scss";

@mixin loginPage {
  display: flex;
  align-items: center;
  height: 100vh;
  background-color: #000;

  @include media-query($medium) {
    padding: 1rem 3rem;
  }

  @include media-query($small) {
    padding: 0;
  }

  &-form,
  &-image {
    flex: 1 1 percentage(1/2);
    height: 100vh;
  }

  &-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 27rem;
    background-color: #fff;
    padding: 3rem 10%;

    @include media-query($medium) {
      padding: 3rem 7rem;
    }

    @include media-query($small) {
      min-width: 23rem;
      padding: 5rem 4rem;
    }

    &__link {
      margin: 0 auto;
    }

    &__form-group {
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;

      input[type="password"],
      input[type="email"] {
        height: 2.5rem;
        padding: 0.5rem;
        border-radius: unset;
        border: 1px solid rgb(134, 134, 134);
        font-size: 1rem;

        @include media-query($small) {
          height: 3rem;
        }
      }
    }

    &__form-label {
      font-size: 0.9rem;

      @include media-query($small) {
        font-size: 1rem;
        margin-bottom: 1rem;
      }
    }

    &__text {
      margin-bottom: 1.5rem;

      @include media-query($small) {
        margin-bottom: 3.5rem;
      }
    }

    &__logo {
      height: 5rem;
      margin: 0 0 5rem -2.5rem;

      img {
        display: block;
        height: 100%;
        width: auto;
      }

      @include media-query($small) {
        margin-bottom: 1rem;
      }
    }

    &__form {
      display: flex;
      flex-direction: column;
    }

    &__button {
      font-weight: lighter;
      font-size: small;
    }
  }

  &-image {
    img {
      transform: scaleX(-1);
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    @include media-query($medium) {
      display: none;
    }
  }
}

.login {
  @include loginPage;
}
