@import "../../../sass/main.scss";

.onboarding-checklist {
  .onboarding-title {
    margin-bottom: 3.25rem;
  }

  .onboarding-checkbox {
    &:not(:last-child) {
      margin-bottom: 2.75rem;
    }
  }

  .checkbox-link {
    text-decoration: underline;
    cursor: pointer;
  }

  .onboarding-button {
    margin-top: 3.5rem;
    width: 100%;
  }

  .onboarding-back-btn {
    margin-top: 1.375rem;

    @include media-query($medium-up) {
      margin-top: 0.625rem;
    }
  }

  .no-tc-title {
    font-size: 1.2rem;

    @include media-query($medium-up) {
      font-size: 1.5rem;
    }
  }
}
