@import "../../sass/main";

.footer {
  padding: 1rem 15rem;
  text-align: center;
  color: white;
  background-color: black;

  &__image {
    display: block;
    max-width: 21rem;
    width: 40%;
    margin: 0 auto;
  }

  @include media-query($small) {
    padding: 0.5rem 2rem;
  }

  @include media-query($medium) {
    padding: 0.5rem 3rem;
  }
}
