@import "../../../../../sass/main.scss";

input[type="text"].form-field,
input[type="number"].form-field,
select.form-field,
textarea.form-field {
  background-color: $color-secondary-dark;
  padding: 0.625rem 1.1rem;
  border: unset;
  font-family: inherit;
  letter-spacing: 1px;
  margin-bottom: 1rem;
}
