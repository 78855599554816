@import "../../../sass/main.scss";

.billing-details {
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 400;
  font-family: $font-primary-new;
  margin: 1.25rem 0 2.5rem 0;

  @include media-query($medium-up) {
    align-self: start;
    color: #fff;
    padding: 11rem 6.5rem 0 6.5rem;
    margin: 0;
    font-size: 1.125rem;
    line-height: 1.5rem;
  }

  &__title {
    margin-bottom: 0.75rem;
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 400;

    @include media-query($medium-up) {
      margin-bottom: 1rem;
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
  }

  &__summary-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem 0;
    font-weight: 300;

    &:not(:last-child) {
      border-bottom: solid 1px rgba(0, 0, 0, 0.5);
    }

    @include media-query($medium-up) {
      height: 7rem;
      background-color: rgba(255, 255, 255, 0.22);
      padding: 1.5rem 1.75rem;
      margin-bottom: 1rem;
      border: none !important;
    }

    &__label {
      & > :first-child {
        margin-bottom: 2px;

        @include media-query($medium-up) {
          margin-bottom: 4px;
        }
      }

      & > :not(:first-child) {
        font-size: 0.75rem;
        line-height: 1rem;

        @include media-query($medium-up) {
          font-size: 0.875rem;
          line-height: 1.125rem;
        }
      }
    }

    &__price {
      font-weight: 800;
      text-transform: uppercase;

      &.free {
        color: $color-green-primary;
      }
    }
  }

  &__pricing-block {
    display: flex;
    margin-top: 1.25rem;

    @include media-query($medium-up) {
      margin-top: 2.5rem;
    }

    &__column {
      &:first-child {
        margin-right: 1.5rem;
        white-space: nowrap;
      }

      &:last-child {
        text-align: right;

        @include media-query($medium-up) {
          text-align: left;
        }
      }

      & > :not(:last-child) {
        margin-bottom: 1.5rem;
      }

      .recurring-payment-details {
        font-size: 0.75rem;
        line-height: 1rem;

        @include media-query($medium-up) {
          font-size: inherit;
          line-height: inherit;
        }
      }
    }
  }

  &__explanation {
    margin: 2rem 0 0 0;
    font-size: 0.75rem;
    line-height: 1rem;

    @include media-query($medium-up) {
      margin: 3rem 0 0 0;
    }
  }
}
