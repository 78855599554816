@import "../../sass/main.scss";

.navbar {
  $color-disabled-tab: $color-secondary-dark;

  width: 100%;
  background-color: $color-disabled-tab;

  &__items {
    display: flex;
    list-style-type: none;
  }
}
