// Base

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  font-size: 16px * 0.75;
  line-height: 1.5;

  @include media-query($medium-up) {
    font-size: 16px;
  }
}

article {
  margin: 1.5rem auto 2rem auto;
}

button {
  border: none;
  border-radius: 0;
  background-color: unset;
}

a {
  color: black;
  text-decoration: none;
}

// TODO: this style must be removed after the new UI v2 is complete
p {
  margin: 1rem auto;
}

ul {
  list-style: none;
}

fieldset {
  border: 0;
  padding: 0.01em 0 0 0;
  margin: 0;
  min-width: 0;
}

body:not(:-moz-handler-blocked) fieldset {
  display: table-cell;
}
