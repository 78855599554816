@import "../../../sass/main.scss";

.explainer {
  padding: 7.5rem 2.5rem;

  @include media-query($medium-up) {
    padding: 5.5rem 5.25rem;
  }

  .onboarding-title {
    margin-bottom: 3.25rem;

    @include media-query($medium-up) {
      margin-bottom: 3.125rem;
    }
  }

  .onboarding-button {
    margin-top: 4.875rem;

    @include media-query($medium-up) {
      margin-top: 10.875rem;
    }
  }
}
