@import "../../sass/main.scss";

.copy-box {
  &__content {
    display: flex;
    flex: 0 0 85%;
    align-items: center;
    padding: 0.5rem;
  }

  &__button {
    width: 100%;
    background-color: $color-green-primary;
    border: none;
    padding: 1rem;
    margin: 0;
    cursor: pointer;

    color: white;
    text-transform: uppercase;
    font-size: 0.7rem;
    display: flex;
    justify-content: center;
    letter-spacing: 2px;
    text-align: center;
    border-left: 1px solid black;
  }
}
