@import "../../../sass/main.scss";

.onboarding-text-input {
  &__label {
    display: block;
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-family: $font-primary-new;
    letter-spacing: 0.1rem;
    font-weight: 400;
    margin-bottom: 0.625rem;
  }

  &__field {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
    border: none;
    color: $color-secondary;
    background-color: $form-field-bg;
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-family: $font-primary-new;
    margin-bottom: 28px;

    &.has-error {
      margin-bottom: 0;
    }

    @include media-query($medium-up) {
      width: 19.25rem;
    }
  }

  &__error {
    display: block;
    margin: 8px 0;
    color: $color-warning;
    font-size: 12px;
    line-height: 12px;
  }
}
