@import "../../sass/abstracts/_variables.scss";

.tab {
  width: 100%;
  padding: 1rem;
  font-size: 0.7rem;
  text-transform: uppercase;

  &__title {
    letter-spacing: 2px;
    font-weight: 600;
    text-decoration: uppercase;
  }

  &--disabled {
    background-color: $color-text-disabled;
  }

  &--selected {
    color: $color-text-enabled;
    background-color: $color-tab-enabled;
    border-top: $border-props;
    border-left: $border-props;
    border-right: $border-props;
  }

  &--not-selected {
    color: $color-text-disabled;
    background-color: $color-tab-disabled;
  }
}
