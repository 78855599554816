@import "../../../../../sass/main.scss";

.form-group {
  display: flex;
  min-width: 18rem;
  flex: 1;
  flex-direction: column;

  @include media-query($medium-up) {
    flex: unset;
  }
}
