@import "../../sass/main.scss";

.pay-your-balance {
  .loading {
    text-align: center;
  }

  .card-logo {
    margin: 0 0.5rem 0 0;
  }

  .failure-payment {
    color: red;
  }

  .amount-due {
    text-align: center;
    margin: 3rem;

    &__title {
      display: inline;
      font-size: 2.5rem;
    }

    &__total {
      margin: 1rem;
      font-size: 2rem;
    }
  }
}

.processing {
  text-align: center;

  p {
    font-size: 1.1rem;
  }

  .processing__content {
    margin: 2rem;

    .btn {
      margin: 3rem;
      background-color: #000;
    }
  }
}
