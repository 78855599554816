// Mixins

// Media Query Manager

/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
1200 - 1800px:  Normal styles
1800px +:       Large screens

$breakpoint argument choices
- phone
- phone-up
- tab-port
- tab-port-up
- tab-land
- tab-land-up
- large-desktop

ORDER: Base + typography > general layout + grid ? page layout > components

NOTE: 
The min viewport width is set to 18.75em or 300px
Anything below this screen size will cause visual problems

*/
@mixin media-query($breakpoint) {
  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) { // 600px max
      @content;
    }
  }

  @if $breakpoint == phone-up {
    @media only screen and (min-width: 37.5em) { // 600px min
      @content;
    }
  }

  @if $breakpoint == tab-port {
    @media only screen and (max-width: 56.25em) { // 900px max
      @content;
    }
  }

  @if $breakpoint == tab-port-up {
    @media only screen and (min-width: 56.25em) { // 900px min
      @content;
    }
  }

  @if $breakpoint == tab-land {
    @media only screen and (max-width: 75em) { // 1200px max
      @content;
    }
  }

  @if $breakpoint == tab-land-up {
    @media only screen and (min-width: 75em) { // 1200px min
      @content;
    }
  }

  @if $breakpoint == large-desktop {
    @media only screen and (min-width: 112.5em) {  // min 1800px 
      @content;
    }
  }
}
