@import "../../../../sass/main.scss";

.steps {
  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include media-query($medium-up) {
      flex-direction: row;
      column-gap: 1rem;
    }
  }

  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1.18;
  letter-spacing: 2px;
  margin-bottom: 1.35rem;

  @include media-query($medium-up) {
    margin-bottom: 3.87rem;
    font-size: 0.87rem;
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include media-query($medium-up) {
      flex-direction: row;
    }
  }
}
