@import "../../sass/main";

.sidebar {
  flex: 0 0 20%;
  min-width: 17rem;
  background-color: $color-primary-dark;

  &__login-msg {
    text-transform: uppercase;
  }

  &__brand {
    margin: 1rem;
  }

  &__items {
    display: flex;
    flex-direction: column;
  }

  &__item {
    &,
    &:link,
    &:visited {
      display: flex;
      align-items: center;
      padding: 1.5rem 1rem;
      text-transform: uppercase;
      font-weight: 700;
    }

    &--selected {
      background-color: $color-primary-light;
    }

    &--no-child {
      margin: 1rem 0;
      padding-left: 3rem;
    }
  }

  .brand__image {
    height: 100px;
    width: 100%;
    object-fit: contain;
  }
}
