@import "../../sass/main.scss";

.upcoming-sweeps {
  &__box {
    background-color: $color-secondary-dark;
    padding: 1rem;
    margin: 1rem 0;
  }

  &__items {
    list-style: none;
  }

  &__loading-msg,
  &__item,
  &__text {
    margin-top: 0.5rem;
    font-size: 0.8rem;
  }

  &__text,
  &__item {
    a {
      text-decoration: underline;
    }
  }
}
