@import "../../../sass/main.scss";
@import "../../../sass/formsStyles.scss";

.form-container {
  padding-top: 1em;
}

.form__offer-detail {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em 1em;
  grid-template-areas:
    ". ."
    ". ."
    "field field"
    "image image"
    ". ."
    "errors .";
  padding: 1em 0;
}

.form__textarea {
  grid-area: field;
}

.form__images {
  grid-area: image;
}

.form__errors {
  grid-area: errors;
}
