@import "../../../../../sass/main.scss";

.side-bar__item {
  display: block;
  padding: 0.5rem;
  color: white;
  font-size: 0.9rem;
  letter-spacing: 2px;
  line-height: 1.38;

  @include media-query($medium-up) {
    padding: 0.8rem 3.5rem;
    font-size: 1.13rem;
  }

  &:hover,
  &:active,
  &--current {
    background: linear-gradient(
      93.05deg,
      rgba(255, 255, 255, 0.37) 0%,
      rgba(255, 255, 255, 0.1369) 101.74%
    );
    font-weight: 600;
  }
}
