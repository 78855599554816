// Variables
// Colors
$color-primary: #edeeef;
$color-primary-light: #fff;
$color-primary-dark: #d3d8db;

$color-secondary: #444243;
$color-secondary-dark: #efefef;
$color-secondary-transparency: #00000072;

$color-dark: #000000;

$color-warning: #b33a3a;
$color-error: #ff6969;
$color-error-background: #ffdbdb;

$color-link-light-blue: #4d88ff;
$color-link-blue: #39a1ea;

$color-purple-primary: #bf5ef6;

$color-border-dark: #bcbebe;
$border-props: 2px solid $color-border-dark;

$color-text-enabled: #303030;
$color-text-disabled: #c2c3c3;
$color-tab-enabled: $color-primary-light;
$color-tab-disabled: $color-secondary-dark;

$color-green-primary: #49b780;

$form-field-text-color: #9b9b9bc4;
$form-field-bg: $color-secondary-dark;
$blur-text-color: #242a2d9e;
$light-bg-color: #d4d9db;

// Screen sizes
$small: phone;
$medium: tab-port;
$medium-up: tab-port-up;
$large: tab-land;
$extra-large: large-desktop;

// Fonts
$font-primary: "Avenir Next", Arial, Helvetica, sans-serif;
$font-heading: Arapey, Arial, Helvetica, sans-serif;
