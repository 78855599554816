@import "../../../sass/main.scss";

.onboarding-checkbox {
  display: flex;

  &__label {
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-family: $font-primary-new;
    letter-spacing: 0.1rem;
    font-weight: 400;
  }

  &__disabled {
    cursor: default;
    color: $color-text-disabled;

    .checkbox-link {
      cursor: default !important;
      color: $color-text-disabled;
    }
  }

  input[type=checkbox] {
    -ms-transform: scale(1.4) translateY(2px); /* IE */
    -moz-transform: scale(1.4) translateY(2px); /* FF */
    -webkit-transform: scale(1.4) translateY(2px); /* Safari and Chrome */
    -o-transform: scale(1.4) translateY(2px); /* Opera */
    transform: scale(1.4) translateY(2px);
    padding: 10px;
    margin-right: 1.25rem;
  }
}
