@import "../../sass/abstracts/variables";

.sponsor-checklist {
  width: 100%;

  &__title {
    font-family: serif;
    font-weight: 100;
    font-size: 2rem;
  }

  &__subtitle {
    font-size: 1rem;
  }

  .section {
    &__title {
      font-size: 1rem;
      text-transform: uppercase;
    }

    &__text {
      margin-top: 0.5rem;
      font-size: 0.8rem;
    }

    &__text {
      a {
        text-decoration: underline;
      }
    }
  }
}
