@import "../../sass/main.scss";

.home-page {
  &__steps {
    margin-left: 1rem;
    padding-left: 0.5rem;
  }

  &__contact {
    margin: 2rem 0;
    padding: 0.5rem 1rem;
    border: 1px solid;
    font-weight: 600;
  }

  .grid {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));

    &__item {
      padding: 0.5rem 1rem;
      border: 1px solid;
      background-color: $color-primary;

      ul {
        list-style: inside;
      }

      &__title {
        text-align: center;
        font-weight: 600;
      }
    }
  }
}
