@import "../../../sass/main.scss";

.checkout {
  .onboarding-title {
    margin-bottom: 1rem;

    @include media-query($medium-up) {
      margin-bottom: 4.5rem;
    }
  }

  .card-logos {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 2.5rem;

    & > :not(:last-child) {
      margin-right: 4px;
    }
  }

  .credit-card-form {
    @include media-query($medium-up) {
      width: 37.625rem;
    }
  }

  .card-details {
    justify-content: space-between;
  }

  .input-card-number {
    @include media-query($medium-up) {
      width: 37.625rem;
    }
  }

  .input-billing-zip {
    @include media-query($medium-up) {
      width: 9rem;
    }
  }

  .input-cvv {
    @include media-query($medium-up) {
      width: 10.5rem;
    }
  }

  .input-expiration {
    @include media-query($medium-up) {
      width: 11.125rem;
    }
  }

  .onboarding-button {
    width: 100%;
    margin-bottom: 1.25rem;

    @include media-query($medium-up) {
      width: auto;
      margin: 1rem 0 1rem auto;
    }
  }

  .back-btn-container {
    @include media-query($medium-up) {
      display: flex;
      justify-content: flex-end;
    }
  }

  .onboarding-info {
    margin: 4rem 0 0 0;
  }
}

.checkout-state {
  .payment-status {
    margin: 2.25rem 0 4.5rem 0;
    display: flex;
    align-items: center;

    &.nowrap {
      white-space: nowrap;
    }

    & > :not(:last-child) {
      margin-right: 1.5rem;
    }

    @include media-query($medium-up) {
      margin: 3.5rem 0 5rem 0;
    }
  }

  .payment-loading {
    display: block;
    height: 50px;
    margin: 4.5rem auto 2.75rem auto;

    @include media-query($medium-up) {
      margin: 3rem auto;
    }
  }

  .onboarding-button {
    width: 100%;
    margin-bottom: 3.5rem;

    @include media-query($medium-up) {
      margin-bottom: 4rem;
    }

    &.greyed-out {
      background-color: #b2b2b2;
    }
  }

  .onboarding-title + .onboarding-info {
    margin-top: 2rem;

    @include media-query($medium-up) {
      margin-top: 1.5rem;
    }
  }
}
