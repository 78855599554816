@import "../../../../sass/main.scss";

.status-icon {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;

  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.75rem;
  border-radius: 50%;
  border: 1px solid black;

  @include media-query($medium-up) {
    width: 1.5rem;
    height: 1.5rem;
  }

  &--valid {
    border: 1px solid $color-green-primary;
    background-color: $color-green-primary;
  }

  &--invalid {
    border: 1px solid $color-warning;
    background-color: $color-warning;
  }

  &__icon {
    color: white;
    font-size: 0.56rem;

    @include media-query($medium-up) {
      font-size: 0.75rem;
    }
  }
}
