@import "../../sass/abstracts/_variables.scss";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: black;
  text-align: center;

  &__image {
    width: 18rem;
    height: auto;
  }

  &__logout {
    cursor: pointer;
    font-size: 1rem;
    letter-spacing: 1px;
    line-height: 1.5;
    font-family: "Avenir";
  }

  &__logout,
  &__text {
    min-width: 5rem;
    color: white;
    text-transform: uppercase;
    letter-spacing: 2px;

    &:not(:first-child) {
      border-bottom: 1px solid;
    }

    &--external-link {
      font-size: 0.6rem;
      color: $color-link-blue;
    }
  }
}
