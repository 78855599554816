@import "../../sass/main.scss";

.current-sweepstakes {
  font-size: 0.9rem;

  &:not(:last-child) {
    margin-bottom: 2rem;
    border-bottom: 1px solid $color-primary-dark;
  }

  &__title {
    margin-bottom: 1rem;
  }

  &__subtitle {
    font-size: 0.9rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
}

.chart {
  &__heading,
  &__footer {
    display: flex;
    justify-content: space-between;
  }

  &__left-col {
    text-align: left;
  }

  &__right-col {
    text-align: right;
  }

  &__title {
    @extend %heading;
    display: inline-block;
    border-bottom: 1px solid;
    font-size: 1.8rem;
    font-weight: lighter;
    line-height: 1.4rem;
    margin-bottom: 1.5rem;
  }
  &__date {
    margin: 0;
    text-transform: uppercase;
    font-weight: 500;
  }

  &__totals {
    margin: 0;
  }

  &__image {
    width: 100%;
  }

  &__message {
    align-self: flex-end;
    flex: 0 0 60%;
  }

  &__list {
    list-style: none;
  }

  &__notice {
    text-align: right;
    font-size: 0.8rem;
    letter-spacing: 0;
    color: $color-warning;
    margin: 1rem 0;
  }

  &__summary {
    text-align: right;
    margin-left: auto;
  }

  &__viewer {
    min-height: 31rem;
  }
}

.optin__title {
  font-family: "Arapey";
  font-size: 2rem;
  font-weight: unset;
  border-bottom: 1px solid;
  display: inline;
  letter-spacing: 0px;
}
