.row {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;

  p {
    align-self: center;
  }
}

.col {
  display: flex;
  flex-direction: column;

  &--one-half {
    flex: 0 0 percentage(1/2);
  }

  &--one-third {
    flex: 0 0 percentage(1/3);
  }
}
